<template>
  <div class="card h-100">
    <img
      :src="$imageFilters(image)"
      class="card-img-top border-bottom obj-fit-contain cursor-pointer"
      :alt="brand.name"
      @click="$router.push(redirectUrl)"
      style="height: 120px"
    />
    <div class="card-body py-2">
      <ul>
        <li
          class="card-text text-gray-500 fw-light"
          v-for="content in getArrayByLimitLeng(contents, maxTypeCount)"
          :key="content"
        >
          {{ content.nameCh }}
        </li>
      </ul>
      <p
        class="text-center position-absolute end-0 translate-middle-x end-0"
        style="bottom: 0.5rem"
      >
        <a href="#" @click.prevent="$router.push(redirectUrl)" class="text-secondary fs-8 fw-light"
          >了解更多</a
        >
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "brandProductCard",
  props: {
    brand: String,
    image: String,
    redirectUrl: String,
    contents: Array,
    showContent: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      maxTypeCount: null,
    };
  },
  computed: {
    getArrayByLimitLeng() {
      return (arr, max) => (max ? arr.slice(0, max) : arr);
    },
  },
};
</script>
